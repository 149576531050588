//export const DOMAIN = process.env.NODE_ENV === 'production' ? "https://portaleservizi.cnappc.it/cfp" : process.env.REACT_APP_DOMAIN || "http://156.54.30.19:54380/cfp";
//export const DOMAIN = process.env.NODE_ENV === 'production' ? "https://portaleservizi.cnappc.it/cfp" : process.env.REACT_APP_DOMAIN || "http://192.168.201.46:8080";
//export const DOMAIN = process.env.NODE_ENV === 'production' ? "https://portaleservizi.cnappc.it/cfp" : process.env.REACT_APP_DOMAIN || "http://localhost:8080";
//export const DOMAIN = "https://gcfp-collaudo.cnappc.it/cfp";

export const DOMAIN = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_DOMAIN || "https://portaleservizi.cnappc.it/cfp") : (process.env.REACT_APP_DOMAIN || "http://localhost:8080");

if (process.env.NODE_ENV !== 'production') {
  console.debug('NODE_ENV', process.env.NODE_ENV);
  console.debug('DOMAIN', DOMAIN);
}

export const enum ApiEndpoints {
  PolicyConsent = '/user/accept-policy',
  Contact = '/api/contact',
  Register = '/api/registration',
  VerifyAccount = '/api/verify-account',
  ForgotUsername = '/api/forgot-username',
  ForgotPassword = '/auth/forgot-password',
  ResetPassword = '/auth/reset-password',
  ChangePassword = '/auth/change-password',
  Login = '/auth/login',
  LoginSso = '/saml/preauth',
  LoginMockSso = '/auth/mock/login-saml',
  Logout = '/auth/logout',
  UserMe = '/user/me',
  EditUserMe = '/user/me',
  Companies = '/company',
  Partners = '/supplier',
  Company = '/company/me',
  CompaniesOrder = '/company/order',
  CompaniesPartner = '/company/supplier',
  Teachers = '/teacher',
  Teacher = '/teacher/:id',
  TeacherMetadata = '/teacher/metadata',
  Notification = '/notification',
  Courses = '/course',
  CoursesRich = '/course/rich-list',
  CoursesPublished = '/course',
  CoursesMe = '/course/me',
  CoursesExport = '/course/export',
  MyCoursesExport = '/course/:codice_fiscale/professional/export',
  CoursesImport = '/course/import',
  SubscriberExport = '/course/:id/enrolled/export',
  SubscriberCustomImport = '/course/:id/iscritti/customimport',
  Course = '/course/:id',
  Favorites = '/course/favorite',
  CourseDetail = '/course-detail/:id',
  AddCourse = '/course',
  EditCourse = '/course/:id',
  DeleteCourse = '/course/:id',
  CourseMetadata = '/course/metadata',
  SubscriberImport = '/course/:id/iscritti/import',
  AddSubscriber = '/course/:id/subscribe',
  EditSubscription = '/course/:id/subscription/:cf/:state',
  EditSubscriptionsStatus = '/course/:id/subscription/status',
  DeleteSubscription = '/course/:id/iscritti',
  CourseTeachers = '/course/:id/teachers',
  CourseTeacher = '/course/:id/teacher/:id',
  CourseAttachments = '/course/:id/attachments',
  CourseSubscriptionByMoodleInspect = '/course/:id/subscription-by-moodle/:cf',
  Attachments = '/attachment',
  Attachment = '/attachment/:id',
  DeleteAttachment = '/attachment/:id',
  CourseAttachment = '/course/:id/attachment',
  CourseSubscribers = '/course/:id/subscribers',
  CourseSubscriptions = '/course/:id/subscriptions',
  CourseSubscription = '/course/:id/subscription/:id',
  Message = '/support',
  Professionals = '/professional',
  ProfessionalsEmail = '/professional/email/list',
  ProfessionalsEmailSend  = '/professional/email/send',
  ProfessionalsEmailCount = '/professional/email/count',
  ProfessionalEmailSearch = '/professional/email/search',
  ProfessionalSearch = '/professional-search',
  ProfessionalsExport = '/professional/export',
  ProfessionalCertificateImport = '/professional/certificate/import',
  ProfessionalsFromAwn = '/professional/awn',
  ProfessionalStatus = '/professional/status',
  ProfessionalStatusFormativo = '/professional/statusformativo',
  ProfessionalExportStatusCfp = '/professional/export-statuscfp',
  Professional = '/professional/:cf',
  ProfessionalCheckBeforeReplaceCf = '/professional/check-before-replace-cf',
  ProfessionalReplaceCf = '/professional/replace-cf',
  ImateriaHistoryProfessionalStatus = '/imateria/storico-cfp/:cf',
  CertificationsProfessional = '/certification/:cf/professional',
  CertificationsNonStandardProfessional = "/certification/nonstandard",
  CoursesProfessional = '/course/:cf/professional',
  CertificationsExport = '/certification/export',
  Certifications = '/certification',
  Certification = '/certification/:id',
  CertificationMetadata = '/certification/metadata',
  AddCertification = '/certification',
  AddCertificationAttachment = '/certification/:id/attachment',
  EditCertification = '/certification/:id',
  DeleteCertification = '/certification/:id',
  EditCertificationsStatus = '/certification/status/:state',
  CoursePaymentReceipt = '/course/:id/payment',
  AddToPendingList = '/course/:id/pending-list',
  GetPaymentTReceipt = '/course/:id/professional/:cf/payment',
  DeleteSubscriptionAndPendingPaymentUser = '/course/:id/professional/:codiceFiscale/payment',
  DeleteSubscriptionAndPendingPaymentWithTimingUser = '/course/:id/professional/:codiceFiscale/payment-with-timing',
  UnsubscribeCourseUser = '/course/:id_corso/unsubscribe/:codiceFiscale',
  AppuserOtp = '/user/otp',
  LineeGuida = '/lineeguida',
  DocumentCourseProfessionalCertificate = '/document/course-professional-certificate',
  DocumentProfessionalCv = '/document/professional-cv',
  AdvicesReadonly = '/advice-readonly',
  Advices = '/advice',
  Advice = '/advice/:id',
  AddAdvice = '/advice',
  EditAdvice = '/advice/:id',
  DeleteAdvice = '/advice/:id',
  InspectDoc = '/inspect-doc',
}
