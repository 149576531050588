import { format, parseISO } from 'date-fns';
import { ICourse, ICourseEntity, ICourseEntityRaw, ICourseModality, ICourseModalityRaw, ICourseRaw, ICourseValuation, ICourseValuationRaw } from '../entities/course.entity';
import { ISubscriptionEntity } from '../entities/subscription.entity';
import { SubscriptionState } from '../enums/subscription-state.enum';

export const parseISOStringToStandardDate = (value: string | null | undefined): string => {
  if (value && value.length) {
    const dt: Date = parseISO(value);
    return format(dt, 'yyyy-MM-dd');
  }
  return '';
};

export const parseISOStringToStandardDateTime = (value: string | null | undefined): string => {
  if (value && value.length) {
    const dt: Date = parseISO(value);
    return dt.toISOString();
  }
  return '';
};

export const parseRawToICourse = (value: ICourseRaw): ICourse => {
  const course: ICourse = {
    ...value,
    dataIstanza: (value.dataIstanza ? new Date(value.dataIstanza) : null),
    dataProtIn: (value.dataProtIn ? new Date(value.dataProtIn) : null),
    dataInizio: (value.dataInizio ? new Date(value.dataInizio) : null),
    dataFine: (value.dataFine ? new Date(value.dataFine) : null),
    dataCreazione: (value.dataCreazione ? new Date(value.dataCreazione) : null),
  } as ICourse;

  return course;
};

export const parseICourseToRaw = (value: ICourse): ICourseRaw => {
  const course: ICourseRaw = {
    ...value,
    dataIstanza: (value.dataIstanza ? value.dataIstanza.toISOString() : null),
    dataProtIn: (value.dataProtIn ? value.dataProtIn.toISOString() : null),
    dataInizio: (value.dataInizio ? value.dataInizio.toISOString() : null),
    dataFine: (value.dataFine ? value.dataFine.toISOString() : null),
    dataCreazione: (value.dataCreazione ? value.dataCreazione.toISOString() : null),
  } as ICourseRaw;

  return course;
};

export const parseRawToICourseEntity = (value: ICourseEntityRaw): ICourseEntity => {
  const course: ICourseEntity = {
    ...value,
    dataIstanza: new Date(value.dataIstanza),
    dataProtIn: new Date(value.dataProtIn),
    dataInizio: new Date(value.dataInizio),
    dataFine: new Date(value.dataFine),
    dataCreazione: new Date(value.dataCreazione),
    valutazione: parseRawToICourseValuation(value.valutazione),
    modalita: parseRawToICourseModality(value.modalita),
  } as ICourseEntity;

  return course;
};

export const parseICourseEntityToRaw = (value: ICourseEntity): ICourseEntityRaw => {
  const course: ICourseEntityRaw = {
    ...value,
    dataIstanza: value.dataIstanza.toISOString(),
    dataProtIn: value.dataProtIn.toISOString(),
    dataInizio: value.dataInizio.toISOString(),
    dataFine: value.dataFine.toISOString(),
    dataCreazione: value.dataCreazione.toISOString(),
    valutazione: parseICourseValuationToRaw(value.valutazione),
    modalita: parseICourseModalityToRaw(value.modalita),
  } as ICourseEntityRaw;

  return course;
};

export const parseRawToICourseValuation = (value: ICourseValuationRaw): ICourseValuation => {
  const course: ICourseValuation = {
    ...value,
    dataDelibera: new Date(value.dataDelibera),
    dataInvioIstanza: new Date(value.dataInvioIstanza),
    dataProtOut: new Date(value.dataProtOut),
    dataValutazioneConformita: new Date(value.dataValutazioneConformita),
    dataValutazioneInterna: new Date(value.dataValutazioneInterna),
  } as ICourseValuation;

  return course;
};

export const parseICourseValuationToRaw = (value: ICourseValuation): ICourseValuationRaw => {
  const course: ICourseValuationRaw = {
    ...value,
    dataDelibera: value.dataDelibera.toISOString(),
    dataInvioIstanza: value.dataInvioIstanza.toISOString(),
    dataProtOut: value.dataProtOut.toISOString(),
    dataValutazioneConformita: value.dataValutazioneConformita.toISOString(),
    dataValutazioneInterna: value.dataValutazioneInterna.toISOString(),
  } as ICourseValuationRaw;

  return course;
};

export const parseRawToICourseModality = (value: ICourseModalityRaw): ICourseModality => {
  const course: ICourseModality = {
    ...value,
    dataIscrizioneAl: new Date(value.dataIscrizioneAl),
    dataIscrizioneDal: new Date(value.dataIscrizioneDal),
  } as ICourseModality;

  return course;
};

export const parseICourseModalityToRaw = (value: ICourseModality): ICourseModalityRaw => {
  const course: ICourseModalityRaw = {
    ...value,
    dataIscrizioneAl: value.dataIscrizioneAl.toISOString(),
    dataIscrizioneDal: value.dataIscrizioneDal.toISOString(),
  } as ICourseModalityRaw;

  return course;
};

export const formatICourseEntityRawDate = (value: ICourseEntityRaw): ICourseEntityRaw => {
  const moodleCourseId: string = value.moodleCourseId ? value.moodleCourseId : "";
  const nPartecipantiMin: number = !isNaN(value.nPartecipantiMin) && value.nPartecipantiMin > 0 ? value.nPartecipantiMin : 0;
  const nPartecipantiMax: number = !isNaN(value.nPartecipantiMax) && value.nPartecipantiMax > 0 ? value.nPartecipantiMax : 0;
  const nPartecipantiExt: number = !isNaN(value.nPartecipantiExt) && value.nPartecipantiExt > 0 ? value.nPartecipantiExt : 0;
  
  const course: ICourseEntityRaw = {
    ...value,
    moodleCourseId: moodleCourseId,
    nPartecipantiMin: nPartecipantiMin,
    nPartecipantiMax: nPartecipantiMax,
    nPartecipantiExt: nPartecipantiExt,
    dataIstanza: parseISOStringToStandardDate(value.dataIstanza),
    dataProtIn: parseISOStringToStandardDate(value.dataProtIn),
    dataInizio: parseISOStringToStandardDate(value.dataInizio),
    dataFine: parseISOStringToStandardDate(value.dataFine),
    // dataInizio: parseISOStringToStandardDateTime(value.dataInizio),
    // dataFine: parseISOStringToStandardDateTime(value.dataFine),
    valutazione: formatICourseValuationRawDate(value.valutazione),
    modalita: formatICourseModalityRawDate(value.modalita),
    iscritti: value.iscritti.map((elem: ISubscriptionEntity) => setSubscriptionState(elem)),
  } as ICourseEntityRaw;

  return course;
};

export const formatICourseValuationRawDate = (value: ICourseValuationRaw): ICourseValuationRaw => {
  const dettagliStato: string = value.dettagliStato ? value.dettagliStato : "";
  const idAccessibilita = !isNaN(value.idAccessibilita) && value.idAccessibilita > 0 ? value.idAccessibilita : 0;
  const idStato = !isNaN(value.idStato) && value.idStato > 0 ? value.idStato : 0;
  const idArticolazioneCorso = !isNaN(value.idArticolazioneCorso) && value.idArticolazioneCorso > 0 ? value.idArticolazioneCorso : 0;

  const course: ICourseValuationRaw = {
    ...value,
    dettagliStato: dettagliStato,
    idAccessibilita: idAccessibilita,
    idStato: idStato,
    idArticolazioneCorso: idArticolazioneCorso,
    dataDelibera: parseISOStringToStandardDate(value.dataDelibera),
    dataInvioIstanza: parseISOStringToStandardDate(value.dataInvioIstanza),
    dataProtOut: parseISOStringToStandardDate(value.dataProtOut),
    dataValutazioneConformita: parseISOStringToStandardDate(value.dataValutazioneConformita),
    dataValutazioneInterna: parseISOStringToStandardDate(value.dataValutazioneInterna),
  } as ICourseValuationRaw;

  return course;
};

export const formatICourseModalityRawDate = (value: ICourseModalityRaw): ICourseModalityRaw => {
  const accontoIscrizioneA = !isNaN(value.accontoIscrizioneA) && value.accontoIscrizioneA > 0 ? value.accontoIscrizioneA : 0;
  const accontoIscrizioneB = !isNaN(value.accontoIscrizioneB) && value.accontoIscrizioneB > 0 ? value.accontoIscrizioneB : 0;
  const accontoIscrizioneC = !isNaN(value.accontoIscrizioneC) && value.accontoIscrizioneC > 0 ? value.accontoIscrizioneC : 0;
  const saldoEntroGg = !isNaN(value.saldoEntroGg) && value.saldoEntroGg > 0 ? value.saldoEntroGg : 0;
  const course: ICourseModalityRaw = {
    ...value,
    accontoIscrizioneA: accontoIscrizioneA,
    accontoIscrizioneB: accontoIscrizioneB,
    accontoIscrizioneC: accontoIscrizioneC,
    saldoIscrizioneA: 0,
    saldoIscrizioneB: 0,
    saldoIscrizioneC: 0,
    saldoEntroGg: saldoEntroGg,
    dataIscrizioneAl: parseISOStringToStandardDateTime(value.dataIscrizioneAl),
    dataIscrizioneDal: parseISOStringToStandardDate(value.dataIscrizioneDal),
  } as ICourseModalityRaw;

  return course;
};

export const setSubscriptionState = (value: ISubscriptionEntity): ISubscriptionEntity => {
  const dCancPres: string | null = value?.dtCancPres && value.dtCancPres.length > 0 ? value.dtCancPres : null;
  const dConfPres: string | null = value?.dtConfPres && value.dtConfPres.length > 0 ? value.dtConfPres : null;

  const dCancReg: string | null = value?.dtCancReg && value.dtCancReg.length > 0 ? value.dtCancReg : null;
  const dConfReg: string | null = value?.dtConfReg && value.dtConfReg.length > 0 ? value.dtConfReg : null;
  
  const dInsReg: string | null = value?.dtInsReg && value.dtInsReg.length > 0 ? value.dtInsReg : null;

  const inAttesaAcc: boolean = value?.inAttesaAcconto && value.inAttesaAcconto > 0 ? true : false;
  
  let state = SubscriptionState.Pending;

  if (dCancPres) {
    state = SubscriptionState.NonAttendance;
  }
  else if (dConfPres) {
    state = SubscriptionState.Attendance;
  }
  else if (dCancReg) {
    state = SubscriptionState.Canceled;
  }
  else if (dConfReg) {
    if(inAttesaAcc) {
      state = SubscriptionState.AttemptPayment;
    }
    else {
      state = SubscriptionState.Confirmed;
    }
  }
  else if (dInsReg) {
    state = SubscriptionState.Ingoing;
  }
  else {
    state = SubscriptionState.Pending;
  }

  const subscription: ISubscriptionEntity = {
    ...value,
    state: state,
  } as ISubscriptionEntity;

  return subscription;
};