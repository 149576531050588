import { format } from 'date-fns';
import { FC, Fragment } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { ICompanyEntity } from '../../entities/company.entity';
import { ICourseEntityRaw } from '../../entities/course.entity';
import { IMetadataOption, IMetadataOptionNode } from '../../entities/metadata.entity';
import { RootState } from '../../redux/store';
import { paginationComponentOptions } from '../../utils/list-default';
import { getAreaFormativa, getIsDeontologico } from '../shared/dropdown/course/AreaFormativa';
import { getStatus } from '../shared/dropdown/course/Status';
import { getOrdineProvinciale } from '../shared/dropdown/OrdineProvinciale';

interface IProps {
  courses: Array<ICourseEntityRaw>;
}

const ReplaceCfProfessionalCourses: FC<IProps> = (props) => {

  const companyOrder: Array<ICompanyEntity> = useSelector((s: RootState) => s.companies.orders);
  const categorie: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.course.area_formativa_corso);

  const columns: TableColumn<ICourseEntityRaw>[] = [
    {
      name: 'Codice',
      selector: (row: ICourseEntityRaw) => row.codice,
      sortable: true,
    },
    {
      name: 'Ordine',
      selector: (row: ICourseEntityRaw) => getOrdineProvinciale(row.idAziendaProponente, companyOrder),
      sortable: true,
    },
    {
      name: 'Titolo',
      selector: (row: ICourseEntityRaw) => row.titolo,
      sortable: true,
    },
    {
      name: 'Area formativa',
      selector: (row: ICourseEntityRaw) => getAreaFormativa(row.idAreaFormativa, categorie),
      sortable: true,
    },
    {
      name: 'Inizio',
      sortable: true,
      grow: true,
      selector: (row: ICourseEntityRaw) => {
        if (!row.dataInizio) return '';
        return format(new Date(row.dataInizio), 'dd/MM/yyyy');
      },
      sortFunction: (a: ICourseEntityRaw, b: ICourseEntityRaw) => {
        return new Date(b.dataInizio).getTime() - new Date(a.dataInizio).getTime();
      },
    },
    {
      name: 'Fine',
      sortable: true,
      grow: true,
      selector: (row: ICourseEntityRaw) => {
        if (!row.dataFine) return '';
        return format(new Date(row.dataFine), 'dd/MM/yyyy');
      },
      sortFunction: (a: ICourseEntityRaw, b: ICourseEntityRaw) => {
        return new Date(b.dataFine).getTime() - new Date(a.dataFine).getTime();
      },
    },
    {
      name: 'Presenza',
      sortable: true,
      selector: (row: ICourseEntityRaw) => {
        return getStatus(row.iscritti[0].state);
      },
      grow: true,
    },
    {
      name: 'Crediti O',
      selector: (row: ICourseEntityRaw) => {
        if (getIsDeontologico(row.idAreaFormativa, categorie)) return '';
        return row.cpfAssegnati;
      },
      sortable: true,
      grow: true,
    },
    {
      name: 'Crediti D',
      selector: (row: ICourseEntityRaw) => {
        if (!getIsDeontologico(row.idAreaFormativa, categorie)) return '';
        return row.cpfAssegnati;
      },
      sortable: true,
      grow: true,
    },
    // {
    //   name: 'Crediti',
    //   selector: (row: ICourseEntityRaw) => row.cpfAssegnati,
    //   sortable: true,
    //   grow: true,
    // },
  ] as TableColumn<ICourseEntityRaw>[];

  return (
    <Fragment>
      <DataTable columns={columns} data={props.courses}
              pagination striped dense
              noDataComponent="Nessun risultato"
              paginationComponentOptions={paginationComponentOptions} />
      
    </Fragment>
  );
};

export default ReplaceCfProfessionalCourses;