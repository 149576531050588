export const getBase64 = (file: File, callback: Function): void => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => callback(reader.result);

    reader.onerror = (error) => console.error('FileToBase64 error', error);
};

/*
export const maintenanceModeUrl: string = process.env.NODE_ENV === 'production' ? '/stop.html' : '/assets/maintenance/stop.html';

export const webResourceExists = async (url: string) => {
  const result = await fetch(url, { method: 'HEAD' });
  return result.status === 200;
}
*/

export const maintenanceModeUrl: string = process.env.PUBLIC_URL + '/envconfig.json';

export const webResourceExists = async (url: string, user: string) => {
  if ( user != null && (user === "managertest" || user == "raffaella.giannotti.assistenza" 
    || user == "CNRSS3947"|| user == "stefano.austini.assistenza" 
    || user == "antonio.schiavo.assistenza"|| user == "roberto.ottaviani.assistenza"
    || user.endsWith('@821') || user.endsWith('@811') || user.endsWith('@827'))) {
      return false;
    }
  const api_call = await fetch(url);
  const data = await api_call.json();
  //return data?.maintenance;
  return (process.env.NODE_ENV === 'production') && (data?.maintenance);
}