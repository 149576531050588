import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse } from '../../api/api.interface';
import ProfessionalAPI from "../../api/professional.api";
import { ICertificationEntity } from "../../entities/certification.entity";
import { ICourseEntityRaw } from "../../entities/course.entity";
import { IProfessionalEntity } from "../../entities/professional.entity";
import { ImateriaHistoryProfessionalStatusEntity, IProfessionalStatusEntity } from "../../entities/training-credits.entity";
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';
import { ISubscriptionEntity } from "../../entities/subscription.entity";
import { setSubscriptionState } from "../../utils/course-parser";

export interface IGetImateriaHistoryProfessionalStatusFilter {
  triennio: number;
}

export interface IGetImateriaHistoryProfessionalStatusThunkPayload {
  codiceFiscale: string;
  filter: IGetImateriaHistoryProfessionalStatusFilter;
}

export interface IGetImateriaHistoryProfessionalStatusFulfilledPayload {
  trainingCreditsHistory: Array<ImateriaHistoryProfessionalStatusEntity>;
}

export const getImateriaHistoryProfessionalStatus = createAsyncThunk(
  'professional/imateria/history/status/get',
  async (thunkPayload: IGetImateriaHistoryProfessionalStatusThunkPayload, thunkAPI) => {
    try {
      const response = await ProfessionalAPI.getImateriaHistoryProfessionalStatus(thunkPayload);
      const successPayload = response.data as unknown as Array<ImateriaHistoryProfessionalStatusEntity>;
      const fulfillValue = {
        trainingCreditsHistory: successPayload
      } as IGetImateriaHistoryProfessionalStatusFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface ICoursesProfessionalFilter {
  confermato: number;
  anno: number;
  deontologico: number;
  statoIscrizione: number | null;
  triennio: number | null;
}

export interface ICoursesProfessionalThunkPayload {
  codiceFiscale: string;
  filter: ICoursesProfessionalFilter;
}

export interface ICoursesProfessionalFulfilledPayload {
  courses: Array<ICourseEntityRaw>;
}

export const getCoursesProfessional = createAsyncThunk(
  'professional/courses/get',
  async (thunkPayload: ICoursesProfessionalThunkPayload, thunkAPI) => {
    try {
      const response = await ProfessionalAPI.getCoursesProfessional(thunkPayload);
      const successPayload = response.data as unknown as Array<ICourseEntityRaw>;
      const fulfillValue = {
        courses: successPayload
      } as ICoursesProfessionalFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface ICertificationsProfessionalThunkPayload {
  codiceFiscale: string;
}

export interface ICertificationsProfessionalFulfilledPayload {
  certifications: Array<ICertificationEntity>;
}

export interface ICertificationsNonStandardProfessionalThunkPayload {
  codiceFiscale: string;
}

export interface ICertificationsNonStandardProfessionalFulfilledPayload {
  certificationsNonStandard: Array<ICertificationEntity>;
}

export const getCertificationsProfessional = createAsyncThunk(
  'professional/certifications/get',
  async (thunkPayload: ICertificationsProfessionalThunkPayload, thunkAPI) => {
    try {
      const response = await ProfessionalAPI.getCertificationsProfessional(thunkPayload);
      const successPayload = response.data as unknown as Array<ICertificationEntity>;
      const fulfillValue = {
        certifications: successPayload
      } as ICertificationsProfessionalFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface ICertificationsPagination {
  page: number;
  limit: number;
}

export interface ICertificationsNonStandardThunkPayload {codiceFiscale: string, pagination: ICertificationsPagination}

export const getCertificationsNonStandardProfessional = createAsyncThunk(
  'professional/certifications/nonstandard/get',
  async (thunkPayload: ICertificationsNonStandardProfessionalThunkPayload, thunkAPI) => {
    try {
      const response = await ProfessionalAPI.getCertificationsNonStandardProfessional(thunkPayload);
      const successPayload = response.data as unknown as Array<ICertificationEntity>;
      const fulfillValue = {
        certificationsNonStandard: successPayload
      } as ICertificationsNonStandardProfessionalFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IProfessionalStatusFilter {
  triennio: number;
  codiceFiscale: string;
}

// export interface IProfessionalStatusFormativoFilter {
//   codiceFiscale: string;
// }

export interface IProfessionalStatusThunkPayload {
  filter: IProfessionalStatusFilter;
}

// export interface IProfessionalStatusFormativoThunkPayload {
//   filter: IProfessionalStatusFormativoFilter;
// }

export interface IProfessionalStatusFulfilledPayload {
  trainingCredits: Array<IProfessionalStatusEntity>;
}

// export interface IProfessionalStatusFormativoFulfilledPayload {
//   statusFormativo: IProfessionalStatusFormativoEntity;
//   // statusFormativo: Array<IProfessionalStatusFormativoEntity>;
// }

export const getProfessionalStatus = createAsyncThunk(
  'professional/status/get',
  async (thunkPayload: IProfessionalStatusThunkPayload, thunkAPI) => {
    try {
      const response = await ProfessionalAPI.getProfessionalStatus(thunkPayload);
      const successPayload = response.data as unknown as Array<IProfessionalStatusEntity>;
      const fulfillValue = {
        trainingCredits: successPayload
      } as IProfessionalStatusFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

// export const getProfessionalStatusFormativo = createAsyncThunk(
//   'professional/statusformativo/get',
//   async (thunkPayload: IProfessionalStatusFormativoThunkPayload, thunkAPI) => {
//     try {
//       const response = await ProfessionalAPI.getProfessionalStatusFormativo(thunkPayload);
//       const successPayload = response.data as unknown as IProfessionalStatusFormativoEntity;
//       // const successPayload = response.data as unknown as Array<IProfessionalStatusFormativoEntity>;

//       const fulfillValue = {
//         statusFormativo: successPayload
//       } as IProfessionalStatusFormativoFulfilledPayload;
//       return thunkAPI.fulfillWithValue(fulfillValue);
//     } catch (e) {
//       const error = e as AxiosError;
//       if (!error.isAxiosError) throw e;
//       const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
//       return thunkAPI.rejectWithValue(rejectValue);
//     }
//   }
// );

export interface IGetProfessionalThunkPayload {
  codiceFiscale: string;
}

export interface IGetProfessionalFulfilledPayload {
  professional: IProfessionalEntity;
}

export const getProfessional = createAsyncThunk(
  'professional/get',
  async (thunkPayload: IGetProfessionalThunkPayload, thunkAPI) => {
    try {
      const response = await ProfessionalAPI.getProfessional(thunkPayload);
      const successPayload = response.data as unknown as IProfessionalEntity;
      const fulfillValue = {
        professional: successPayload
      } as IGetProfessionalFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IProfessionalCheckBeforeReplaceCfThunkPayload {
  codiceFiscaleOld: string;
  codiceFiscaleNew: string;
}

export interface IProfessionalCheckBeforeReplaceCfFulfilledPayload { }

export const postProfessionalCheckBeforeReplaceCf = createAsyncThunk(
  'professional/check-before-replace-cf/post',
  async (thunkPayload: IProfessionalCheckBeforeReplaceCfThunkPayload, thunkAPI) => {
    try {
      await ProfessionalAPI.postProfessionalCheckBeforeReplaceCf(thunkPayload);
      //const response = await ProfessionalAPI.postProfessionalCheckBeforeReplaceCf(thunkPayload);
      //const successPayload = response.data as unknown as IProfessionalEntity;
      const fulfillValue = { } as IProfessionalCheckBeforeReplaceCfFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IProfessionalReplaceCfThunkPayload {
  codiceFiscaleOld: string;
  codiceFiscaleNew: string;
}

export interface IProfessionalReplaceCfFulfilledPayload { }

export const postProfessionalReplaceCf = createAsyncThunk(
  'professional/replace-cf/post',
  async (thunkPayload: IProfessionalReplaceCfThunkPayload, thunkAPI) => {
    try {
      await ProfessionalAPI.postProfessionalReplaceCf(thunkPayload);
      //const response = await ProfessionalAPI.postProfessionalReplaceCf(thunkPayload);
      //const successPayload = response.data as unknown as IProfessionalEntity;
      const fulfillValue = { } as IProfessionalReplaceCfFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export const getProfessionalReplaceCfOldCourses = createAsyncThunk(
  'professional/replace-cf/old/courses/get',
  async (thunkPayload: ICoursesProfessionalThunkPayload, thunkAPI) => {
    try {
      const response = await ProfessionalAPI.getCoursesProfessional(thunkPayload);
      const successPayload = response.data as unknown as Array<ICourseEntityRaw>;
      const fulfillValue = {
        courses: successPayload
      } as ICoursesProfessionalFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export const getProfessionalReplaceCfOldCertifications = createAsyncThunk(
  'professional/replace-cf/old/certifications/get',
  async (thunkPayload: ICertificationsProfessionalThunkPayload, thunkAPI) => {
    try {
      const response = await ProfessionalAPI.getCertificationsProfessional(thunkPayload);
      const successPayload = response.data as unknown as Array<ICertificationEntity>;
      const fulfillValue = {
        certifications: successPayload
      } as ICertificationsProfessionalFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export const getProfessionalReplaceCfNewCourses = createAsyncThunk(
  'professional/replace-cf/new/courses/get',
  async (thunkPayload: ICoursesProfessionalThunkPayload, thunkAPI) => {
    try {
      const response = await ProfessionalAPI.getCoursesProfessional(thunkPayload);
      const successPayload = response.data as unknown as Array<ICourseEntityRaw>;
      const fulfillValue = {
        courses: successPayload
      } as ICoursesProfessionalFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export const getProfessionalReplaceCfNewCertifications = createAsyncThunk(
  'professional/replace-cf/new/certifications/get',
  async (thunkPayload: ICertificationsProfessionalThunkPayload, thunkAPI) => {
    try {
      const response = await ProfessionalAPI.getCertificationsProfessional(thunkPayload);
      const successPayload = response.data as unknown as Array<ICertificationEntity>;
      const fulfillValue = {
        certifications: successPayload
      } as ICertificationsProfessionalFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IProfessionalRawState {
  value: IProfessionalEntity | null;
  trainingCredits: Array<IProfessionalStatusEntity>;
  trainingCreditsImateria: Array<ImateriaHistoryProfessionalStatusEntity>;
  certifications: Array<ICertificationEntity>;
  certificationsNonStandard: Array<ICertificationEntity>;
  courses: Array<ICourseEntityRaw>;
  // statusFormativo: IProfessionalStatusFormativoEntity; 
}

export interface IProfessionalReplaceCfState {
  error: IRejectResponse | null;
  cfOld: IProfessionalRawState;
  cfNew: IProfessionalRawState;
}

export interface IProfessionalState extends IProfessionalRawState {
  replaceCf: IProfessionalReplaceCfState;
}

const initialState: IProfessionalState = {
  value: null,
  trainingCredits: new Array<IProfessionalStatusEntity>(),
  trainingCreditsImateria: new Array<ImateriaHistoryProfessionalStatusEntity>(),
  courses: new Array<ICourseEntityRaw>(),
  certifications: new Array<ICertificationEntity>(),
  certificationsNonStandard: new Array<ICertificationEntity>(),
  // statusFormativo: {} as IProfessionalStatusFormativoEntity,
  // statusFormativo: new Array<IProfessionalStatusFormativoEntity>(),
  replaceCf: { 
    error: null,
    cfOld: {
      value: null,
      trainingCredits: new Array<IProfessionalStatusEntity>(),
      trainingCreditsImateria: new Array<ImateriaHistoryProfessionalStatusEntity>(),
      courses: new Array<ICourseEntityRaw>(),
      certifications: new Array<ICertificationEntity>(),
      certificationsNonStandard: new Array<ICertificationEntity>(),
    } as IProfessionalRawState,
    cfNew: {
      value: null,
      trainingCredits: new Array<IProfessionalStatusEntity>(),
      trainingCreditsImateria: new Array<ImateriaHistoryProfessionalStatusEntity>(),
      courses: new Array<ICourseEntityRaw>(),
      certifications: new Array<ICertificationEntity>(),
      certificationsNonStandard: new Array<ICertificationEntity>(),
    } as IProfessionalRawState,
  } as IProfessionalReplaceCfState,
};

const professionalSlice = createSlice({
  name: "professional",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfessional.pending, (state) => {
      state.value = null;
    });
    builder.addCase(getProfessional.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetProfessionalFulfilledPayload;
      state.value = payload.professional;
    });
    builder.addCase(getProfessional.rejected, (state, action) => {
      state.value = null;
    });

    builder.addCase(getProfessionalStatus.pending, (state) => {
      state.trainingCredits = new Array<IProfessionalStatusEntity>();
    });
    builder.addCase(getProfessionalStatus.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IProfessionalStatusFulfilledPayload;
      state.trainingCredits = payload.trainingCredits;
    });
    builder.addCase(getProfessionalStatus.rejected, (state, action) => {
      //const payload = action.payload as unknown as IRejectResponse;
    });
    
    // builder.addCase(getProfessionalStatusFormativo.pending, (state) => {      
    //   state.statusFormativo = {} as IProfessionalStatusFormativoEntity;
    //   // state.statusFormativo = new Array<IProfessionalStatusFormativoEntity>();
    // });
    // builder.addCase(getProfessionalStatusFormativo.fulfilled, (state, action) => {
    //   const payload = action.payload as unknown as IProfessionalStatusFormativoFulfilledPayload;
    //   state.statusFormativo = payload.statusFormativo;
    // });
    // builder.addCase(getProfessionalStatusFormativo.rejected, (state, action) => {
    //   //const payload = action.payload as unknown as IRejectResponse;
    // });

    builder.addCase(getImateriaHistoryProfessionalStatus.pending, (state) => {
      state.trainingCreditsImateria = new Array<ImateriaHistoryProfessionalStatusEntity>();
    });
    builder.addCase(getImateriaHistoryProfessionalStatus.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetImateriaHistoryProfessionalStatusFulfilledPayload;
      state.trainingCreditsImateria = payload.trainingCreditsHistory;
    });
    builder.addCase(getImateriaHistoryProfessionalStatus.rejected, (state, action) => {
      state.trainingCreditsImateria = new Array<ImateriaHistoryProfessionalStatusEntity>();
    });

    builder.addCase(getCertificationsProfessional.pending, (state) => {
      state.certifications = new Array<ICertificationEntity>();
    });
    builder.addCase(getCertificationsProfessional.fulfilled, (state, action) => {
      const payload = action.payload as unknown as ICertificationsProfessionalFulfilledPayload;
      state.certifications = payload.certifications;
    });
    builder.addCase(getCertificationsProfessional.rejected, (state, action) => {
      state.certifications = new Array<ICertificationEntity>();
    });
    
    builder.addCase(getCertificationsNonStandardProfessional.pending, (state) => {
      state.certificationsNonStandard = new Array<ICertificationEntity>();
    });
    builder.addCase(getCertificationsNonStandardProfessional.fulfilled, (state, action) => {
      const payload = action.payload as unknown as ICertificationsNonStandardProfessionalFulfilledPayload;
      state.certificationsNonStandard = payload.certificationsNonStandard;
    });
    builder.addCase(getCertificationsNonStandardProfessional.rejected, (state, action) => {
      state.certificationsNonStandard = new Array<ICertificationEntity>();
    });

    builder.addCase(getCoursesProfessional.pending, (state) => {
      state.courses = new Array<ICourseEntityRaw>();
    });
    builder.addCase(getCoursesProfessional.fulfilled, (state, action) => {
      const payload = action.payload as unknown as ICoursesProfessionalFulfilledPayload;
      //state.courses = payload.courses;
      state.courses = payload.courses.map(s => {
        if (s.iscritti != null){
          const e: Array<ISubscriptionEntity> = s.iscritti.map((element: ISubscriptionEntity) => {
             return setSubscriptionState(element);
          });  
          s.iscritti = e;
        }
        return s;
      });
    });
    builder.addCase(getCoursesProfessional.rejected, (state, action) => {
      state.courses = new Array<ICourseEntityRaw>();
    });

    builder.addCase(postProfessionalCheckBeforeReplaceCf.pending, (state) => { });
    builder.addCase(postProfessionalCheckBeforeReplaceCf.fulfilled, (state, action) => { });
    builder.addCase(postProfessionalCheckBeforeReplaceCf.rejected, (state, action) => { });

    builder.addCase(postProfessionalReplaceCf.pending, (state) => { });
    builder.addCase(postProfessionalReplaceCf.fulfilled, (state, action) => { });
    builder.addCase(postProfessionalReplaceCf.rejected, (state, action) => { });

    builder.addCase(getProfessionalReplaceCfOldCertifications.pending, (state) => {
      state.replaceCf.cfOld.certifications = new Array<ICertificationEntity>();
    });
    builder.addCase(getProfessionalReplaceCfOldCertifications.fulfilled, (state, action) => {
      const payload = action.payload as unknown as ICertificationsProfessionalFulfilledPayload;
      state.replaceCf.cfOld.certifications = payload.certifications;
    });
    builder.addCase(getProfessionalReplaceCfOldCertifications.rejected, (state, action) => {
      state.replaceCf.cfOld.certifications = new Array<ICertificationEntity>();
    });

    builder.addCase(getProfessionalReplaceCfOldCourses.pending, (state) => {
      state.replaceCf.cfOld.courses = new Array<ICourseEntityRaw>();
    });
    builder.addCase(getProfessionalReplaceCfOldCourses.fulfilled, (state, action) => {
      const payload = action.payload as unknown as ICoursesProfessionalFulfilledPayload;
      //state.courses = payload.courses;
      state.replaceCf.cfOld.courses = payload.courses.map(s => {
        if (s.iscritti != null){
          const e: Array<ISubscriptionEntity> = s.iscritti.map((element: ISubscriptionEntity) => {
             return setSubscriptionState(element);
          });  
          s.iscritti = e;
        }
        return s;  
      });
    });
    builder.addCase(getProfessionalReplaceCfOldCourses.rejected, (state, action) => {
      state.replaceCf.cfOld.courses = new Array<ICourseEntityRaw>();
    });

    builder.addCase(getProfessionalReplaceCfNewCertifications.pending, (state) => {
      state.replaceCf.cfNew.certifications = new Array<ICertificationEntity>();
    });
    builder.addCase(getProfessionalReplaceCfNewCertifications.fulfilled, (state, action) => {
      const payload = action.payload as unknown as ICertificationsProfessionalFulfilledPayload;
      state.replaceCf.cfNew.certifications = payload.certifications;
    });
    builder.addCase(getProfessionalReplaceCfNewCertifications.rejected, (state, action) => {
      state.replaceCf.cfNew.certifications = new Array<ICertificationEntity>();
    });

    builder.addCase(getProfessionalReplaceCfNewCourses.pending, (state) => {
      state.replaceCf.cfNew.courses = new Array<ICourseEntityRaw>();
    });
    builder.addCase(getProfessionalReplaceCfNewCourses.fulfilled, (state, action) => {
      const payload = action.payload as unknown as ICoursesProfessionalFulfilledPayload;
      //state.courses = payload.courses;
      state.replaceCf.cfNew.courses = payload.courses.map(s => {
        if (s.iscritti != null){
          const e: Array<ISubscriptionEntity> = s.iscritti.map((element: ISubscriptionEntity) => {
             return setSubscriptionState(element);
          });  
          s.iscritti = e;
        }
        return s;  
      });
    });
    builder.addCase(getProfessionalReplaceCfNewCourses.rejected, (state, action) => {
      state.replaceCf.cfNew.courses = new Array<ICourseEntityRaw>();
    });
  },
});

export default professionalSlice.reducer;