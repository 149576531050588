import { format } from 'date-fns';
import { FC, Fragment, useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { IoEllipsisVerticalCircle } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { ICertificationEntity } from '../../entities/certification.entity';
import { ICompanyEntity } from '../../entities/company.entity';
import { IMetadataOption, IMetadataOptionNode } from '../../entities/metadata.entity';
import { getCertificationsProfessional, ICertificationsProfessionalThunkPayload } from '../../redux/slices/professional.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { paginationComponentOptions } from '../../utils/list-default';
import CertificationDetail from './CertificationDetail';
import { getNome } from './dropdown/certification/OggettoTipologia';
import { getStatoCertificazione } from './dropdown/certification/StatoCertificazione';
import { getOrdineProvinciale } from './dropdown/OrdineProvinciale';

interface IProps {
  codiceFiscale: string;
  refreshTrigger?: number;
}

const ProfessionalCertifications: FC<IProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();

  const companiesOrders: Array<ICompanyEntity> = useSelector((s: RootState) => s.companies.orders);
  const oggettiAll: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.oggetto_certificazione_all);
  const accreditiStandard: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_accredito_certificazione);
  const esoneri: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_esonero_certificazione);
  const stati: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.certification.stato_certificazione);  
  const accreditiDaDelibera: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_accredito_certificazione_non_standard);
  const [accrediti, setAccrediti] = useState<Array<IMetadataOptionNode>>(new Array<IMetadataOptionNode>());

  const [isLoading, setLoading] = useState(true);

  const certifications: Array<ICertificationEntity> = useSelector((s: RootState) => s.professional.certifications);

  const [selectedItem, setSelectedItem] = useState<ICertificationEntity | null>(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const handleOpenDetailModal = () => setShowDetailModal(true);
  const handleCloseDetailModal = () => {
    setShowDetailModal(false);
    setSelectedItem(null);
  };

  useEffect(() => {
    if (accreditiStandard.length && accreditiDaDelibera.length) {
      setAccrediti([...accreditiStandard, ...accreditiDaDelibera]);
    }
  }, [accreditiStandard, accreditiDaDelibera]);

  useEffect(() => {
    // if (!certifications || (props.refreshTrigger? props.refreshTrigger > 0 : false)) {
      let isMounted = true;
      setLoading(true);
        dispatch(getCertificationsProfessional({ codiceFiscale: props.codiceFiscale } as ICertificationsProfessionalThunkPayload))
          .unwrap()
          .catch((e) => console.error(e))
          .finally(() => { if (isMounted) {setLoading(false);} });

      return () => { isMounted = false; };
    // } else {setLoading(false);}
  }, [dispatch, props.codiceFiscale, props.refreshTrigger]);

  const handleClickItem = (cert: ICertificationEntity) => {
    setSelectedItem(cert);
    handleOpenDetailModal();
  };

  const columns: TableColumn<ICertificationEntity>[] = [
    {
      id: 'id',
      name: '',
      selector: (row: ICertificationEntity) => row.id,
      omit: true,
      grow: 0,
    },
    {
      name: 'Oggetto',
      selector: (row: ICertificationEntity) => getNome(row.idOggetto, oggettiAll),
      sortable: true,
      cell: (row: ICertificationEntity) => {
        return (<span title={getNome(row.idOggetto, oggettiAll)}>{getNome(row.idOggetto, oggettiAll)}</span>);
      },
      grow: 2,
    },
    {
      name: 'Tipologia',
      selector: (row: ICertificationEntity) => (row.idOggetto == 1 || row.idOggetto == 5) ? getNome(row.tipoAccreditoCfpId, accrediti) : getNome(row.tipoEsoneroId, esoneri),
      sortable: true,
      cell: (row: ICertificationEntity) => {
        const nomeTipologia = (row.idOggetto == 1 || row.idOggetto == 5) ? getNome(row.tipoAccreditoCfpId, accrediti) : getNome(row.tipoEsoneroId, esoneri);
        return (<span title={nomeTipologia}>{nomeTipologia}</span>);
      },
      grow: 3,
    },
    {
      name: 'Stato',
      selector: (row: ICertificationEntity) => getStatoCertificazione(row.idStatoCertificazione, stati),
      sortable: true,
      cell: (row: ICertificationEntity) => {
        return (<span title={getStatoCertificazione(row.idStatoCertificazione, stati)}>{getStatoCertificazione(row.idStatoCertificazione, stati)}</span>);
      },
      grow: 1,
    },
    {
      name: 'Crediti',
      selector: (row: ICertificationEntity) => row.cfpAssegnati,
      grow: 1,
    },
    {
      name: 'Periodo rif.',
      selector: (row: ICertificationEntity) => (row.dataRiferimentoDa ? format(new Date(row.dataRiferimentoDa), 'dd/MM/yyyy') : "") + " - " + (row.dataRiferimentoA ? format(new Date(row.dataRiferimentoA), 'dd/MM/yyyy') : ""),
      sortable: true,
      sortFunction: (a: ICertificationEntity, b: ICertificationEntity) => {
        let atime = 0;
        if(a.dataRiferimentoDa && a.dataRiferimentoDa.length > 0) {
          atime = new Date(a.dataRiferimentoDa).getTime();
        }
        let btime = 0;
        if(b.dataRiferimentoDa && b.dataRiferimentoDa.length > 0) {
          btime = new Date(b.dataRiferimentoDa).getTime();
        }
        return atime - btime;
      },
      grow: 2,
    },
    {
      name: 'Ordine provinciale',
      selector: (row: ICertificationEntity) => getOrdineProvinciale(row.idAzienda, companiesOrders),
      grow: 2,
    },
    {
      name: '',
      cell: (row: ICertificationEntity) => {
        return (<IoEllipsisVerticalCircle className="text-dark c-pointer" title="dettagli" size={24} onClick={(e: React.MouseEvent<SVGElement>) => {
          e.preventDefault();
          handleClickItem(row);
        }} />);
      },
      ignoreRowClick: true,
      button: true,
      grow: 1,
    },
  ];

  return (
    <Fragment>
      <Container as="section" fluid className="bg-light p-2">
        <Row>
          <Col>
            <DataTable columns={columns} data={certifications}
              pagination responsive striped progressPending={isLoading}
              noDataComponent="Nessun risultato"
              paginationComponentOptions={paginationComponentOptions} />
          </Col>
        </Row>
      </Container>
      <Modal size="lg" centered show={showDetailModal} onHide={handleCloseDetailModal}>
        <Modal.Header closeButton>
          <Modal.Title>Dettaglio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedItem ? (
            <CertificationDetail certification={selectedItem} />
          ) : (
            <Fragment />
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ProfessionalCertifications;