import { format } from 'date-fns';
import { FC, Fragment, useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Dropdown, FormControl, InputGroup, Modal, Pagination, Row } from 'react-bootstrap';
import { Search, X } from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import { AiOutlineEdit, AiOutlineFileAdd } from "react-icons/ai";
import { BsTrash } from 'react-icons/bs';
import { IoEllipsisVerticalCircle } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { IRejectResponse } from '../../api/api.interface';
import { ICertificationEntity } from '../../entities/certification.entity';
import { IMetadataOption, IMetadataOptionNode } from '../../entities/metadata.entity';
import { deleteCertification, IDeleteCertificationThunkPayload } from '../../redux/slices/certification.slice';
import { getCertifications, ICertificationsFilter, ICertificationsPagination, ICertificationsSorting, ICertificationsThunkPayload, putCertificationsStatus } from '../../redux/slices/certifications.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { CertificationOrderBy } from '../../utils/certification-default';
import CertificationDetail from '../shared/CertificationDetail';
import CertificationEdit from '../shared/CertificationEdit';
import { getNome } from '../shared/dropdown/certification/OggettoTipologia';
// import OggettoTipologia, { getOggetto } from '../shared/dropdown/certification/OggettoTipologia';
import StatoCertificazione, { getStatoCertificazione } from '../shared/dropdown/certification/StatoCertificazione';
import Triennio from '../shared/dropdown/course/Triennio';
import PaginationLimit from '../shared/dropdown/list/PaginationLimit';
import Sorting from '../shared/dropdown/list/Sorting';
import MeseAnno from '../shared/dropdown/MeseAnno';
import PageSelect from '../shared/dropdown/list/PageSelect';
import Oggetto from '../shared/dropdown/certification/Oggetto';
import Tipologia from '../shared/dropdown/certification/Tipologia';
import Esonero from '../shared/dropdown/certification/Esonero';

interface IPagination extends ICertificationsPagination { }
interface IFilter extends ICertificationsFilter { }
interface ISorting extends ICertificationsSorting { }
interface IProps {
  setFilterForCsv?: (triennio: number | null, stato: number | null) => void;
}

const CertificationList: FC<IProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  
  const oggettiAll: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.oggetto_certificazione_all);
  const tipologie: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_accredito_certificazione_all);
  const esoneri: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_esonero_certificazione_all);
  // const tipologie: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_accredito_certificazione);
  // const esoneri: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_esonero_certificazione);
  const stati: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.certification.stato_certificazione);
  const trienni: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.trienni);
  const countTotalCertifications: number = useSelector((s: RootState) => s.certifications.total);
  const accreditiDaDelibera: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_accredito_certificazione_non_standard);

  const [filterByThreeYears,] = useState<IMetadataOption | null>(trienni.length ? trienni[trienni.length - 1] : null);
  const [isLoading, setLoading] = useState(true);
  const [selectedCertificationRows, setSelectedCertificationRows] = useState<Array<ICertificationEntity>>(new Array<ICertificationEntity>());
  const [filterByText, setFilterByText] = useState<string | null>(null);
  const [filter, setFilter] = useState<IFilter>({ meseInizio:0, annoInizio:0, oggetto: 0, tipologia: (-1), company: 0, text: '', stato: 0, triennio: 0, esonero: 0 });
  const [pagination, setPagination] = useState<IPagination>({ page: 1, limit: 25 });
  const [sorting, setSorting] = useState<ISorting>({ orderby: Array<string>(), ordertype: false });
  const [showDelete, setShowDelete] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const error = useSelector((s: RootState) => s.certifications.error as IRejectResponse);
  const certifications: Array<ICertificationEntity> = useSelector((s: RootState) => s.certifications.value);

  const [toggleSelectedCertificationRowsCleared, setToggleSelectedCertificationRowsCleared] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ICertificationEntity | null>(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isMeseAnnoView, setIsMeseAnnoView] = useState<boolean>(true);

  useEffect(() => {
    if (accreditiDaDelibera.length && certifications.length) {      
      console.log("certificazioni non standard: ", accreditiDaDelibera);
      console.log("certificazioni: ", certifications);
    }
  }, [accreditiDaDelibera, certifications]);

  const handleCloseDelete = () => {
    setShowDelete(false);
    setSelectedId(null);
  };
  const handleShowDelete = () => setShowDelete(true);

  const handleOpenEditModal = () => setShowEditModal(true);
  const handleOpenDetailModal = () => setShowDetailModal(true);

  const handleCloseDetailModal = () => {
    setShowDetailModal(false);
    setSelectedItem(null);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedItem(null);
    setPagination({ ...pagination });
  };

  const handleClearChangeStatoCertificazione = () => {
    if (selectedCertificationRows.length) {
      setToggleSelectedCertificationRowsCleared(!toggleSelectedCertificationRowsCleared);
      setSelectedCertificationRows(new Array<ICertificationEntity>());
    }
  };

  useEffect(() => {
    setLoading(true);
    handleClearChangeStatoCertificazione();
    if (props.setFilterForCsv) {
      props.setFilterForCsv(filter?.triennio ? filter.triennio : null,
        filter?.stato ? filter.stato : null
      );
    }
    dispatch(getCertifications({ filter: filter, pagination: pagination, sorting: sorting } as ICertificationsThunkPayload))
      .unwrap()
      .finally(() => setLoading(false));
  }, [dispatch, filter, pagination, sorting]);

  const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setFilterByText(text);
  };

  const handleFilterByText = () => {
    const txt = filterByText ? filterByText : '';
    setFilter({ ...filter, text: txt });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleClearFilterByText = () => {
    setFilterByText('');
    setFilter({ ...filter, text: '' });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByTriennio = (Imet: IMetadataOption | null) => {
    if(Imet == null){
      setIsMeseAnnoView(true);
      setFilter({ ...filter, triennio: undefined, oggetto: 0, tipologia: (-1) });
    } else {  
      setIsMeseAnnoView(false);
      setFilter({ ...filter, triennio: Imet.id, meseInizio: 0, annoInizio: 0, oggetto: 0, tipologia: (-1) });
    }
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByOggetto = (option: IMetadataOption | null) => {
    if (option) {
      setFilter({ ...filter, oggetto: option.id, tipologia: (-1) });
    }
    else {
      setFilter({ ...filter, oggetto: 0, tipologia: (-1) });
    }
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByAnno = (option: number | null) => {
    setFilter({ ...filter, annoInizio: option ? option : 0, oggetto: 0, tipologia: (-1) });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByMese = (option: number | null) => {
    setFilter({ ...filter, meseInizio: option ? option : 0 });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByTipologia = (option: IMetadataOptionNode | null) => {
    if (option) {
      console.log("option", option);
      setFilter({ ...filter, tipologia: option.id });
      // setFilter({ ...filter, oggetto: option.extId, tipologia: option.id });
    }
    else {
      setFilter({ ...filter, oggetto: 0, tipologia: (-1) });
    }
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByEsonero = (option: IMetadataOptionNode | null) => {
    if (option) {
      setFilter({ ...filter, tipologia: option.id });
      // setFilter({ ...filter, oggetto: option.extId, tipologia: option.id });
    }
    else {
      setFilter({ ...filter, oggetto: 0, tipologia: (-1) });
    }
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByStato = (option: IMetadataOption | null) => {
    setFilter({ ...filter, stato: option ? option.id : 0 });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleSortingOrderBy = (ordby: Array<IMetadataOption> | null) => {
    setSorting({ ...sorting, orderby: ordby ? ordby.map(oby => oby.nome) : [] });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleSortingOrderType = (type: boolean) => {
    setSorting({ ...sorting, ordertype: type });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handlePaginationLoad = (pag: number) => {
    setPagination({ ...pagination, page: pag });
  };

  const handlePaginationLimit = (limit: number) => {
    setPagination({ page: 1, limit: limit });
  };

  const handlePaginationLoadMore = () => {
    setPagination({ ...pagination, page: pagination.page + 1 });
  };

  const handlePaginationLoadMinus = () => {
    const previuosPage = pagination.page - 1;
    if (previuosPage === 0) return;
    setPagination({ ...pagination, page: pagination.page - 1 });
  };

  const handleClickItem = (cert: ICertificationEntity) => {
    setSelectedItem(cert);
    handleOpenDetailModal();
  };

  const handleClickItemEdit = (cert: ICertificationEntity) => {
    setSelectedItem(cert);
    handleOpenEditModal();
  };

  const columns: TableColumn<ICertificationEntity>[] = [
    {
      id: 'id',
      name: '#Id',
      selector: (row: ICertificationEntity) => row.id,
      cell: (row: ICertificationEntity) => (<span className="blockquote small m-0 p-0" title={'#' + row?.id.toString()}><b>#</b><small><u>{row.id}</u></small></span>),
      grow: 1,
      omit: true,
    },
    {
      name: 'Professionista',
      selector: (row: ICertificationEntity) => (row.cognome != null ? row.cognome.toUpperCase() : '') + ' ' + row.nome,
      cell: (row: ICertificationEntity) => (<span>{(row.cognome != null ? row.cognome.toUpperCase() : '') + ' ' + row.nome}</span>),
      grow: 2,
    },
    {
      name: 'Codice fiscale',
      selector: (row: ICertificationEntity) => row.codiceFiscale,
      grow: 1,
    },
    {
      name: 'Oggetto',
      selector: (row: ICertificationEntity) => getNome(row.idOggetto, oggettiAll),
      // selector: (row: ICertificationEntity) => getOggetto(row.idOggetto, oggetti),
      omit: (filter.oggetto > 0),
      grow: 2,
    },
    {
      name: 'Tipologia',
      selector: (row: ICertificationEntity) => "",
      cell: (row: ICertificationEntity) => (
        <div title={(row.idOggetto === 1 || row.idOggetto === 5) ? getNome(row.tipoAccreditoCfpId, tipologie) : getNome(row.tipoEsoneroId, esoneri)}>
          {(row.idOggetto === 1 || row.idOggetto === 5) ? getNome(row.tipoAccreditoCfpId, tipologie) : getNome(row.tipoEsoneroId, esoneri)}</div>),
      omit: (filter.tipologia > 0),
      grow: 3,
    },
    {
      name: 'Stato',
      selector: (row: ICertificationEntity) => getStatoCertificazione(row.idStatoCertificazione, stati),
      omit: (filter.stato > 0),
      grow: 1,
    },
    {
      name: 'CFP dic. / assegnati',
      selector: (row: ICertificationEntity) => (((row.idOggetto === 2 && row.idTipologia === 0) 
      || (row.idOggetto === 3 && row.idTipologia === 6) 
      || (row.idOggetto === 4 && row.idTipologia === 0) 
      || (row.idOggetto === 4 && row.idTipologia === 1)) ? "L.G./Aut." : (row.cfpDichiarati + " / " + row.cfpAssegnati)),
      // selector: (row: ICertificationEntity) => row.cfpDichiarati + " / " + row.cfpAssegnati,
      grow: 1,
    },
    {
      name: 'Periodo rif.',
      selector: (row: ICertificationEntity) => (row.dataRiferimentoDa ? format(new Date(row.dataRiferimentoDa), 'dd/MM/yyyy') : "") + " - " + (row.dataRiferimentoA ? format(new Date(row.dataRiferimentoA), 'dd/MM/yyyy') : ""),
      grow: 2,
    },
    {
      selector: (row: ICertificationEntity) => "",
      cell: (row: ICertificationEntity) => (
        <span>
          {!accreditiDaDelibera.some((item) => item.id === row.tipoAccreditoCfpId) && (row.idStatoCertificazione === 1 || row.idStatoCertificazione === 2) && (
            <>
              <BsTrash className="text-danger c-pointer p-0 my-0 ms-0 me-2" title="elimina" size={18} onClick={(e: React.MouseEvent<SVGElement>) => {
                e.preventDefault();
                setSelectedId(row.id);
                handleShowDelete();
              }} />
            <AiOutlineEdit className="text-dark c-pointer p-0 my-0 ms-0 me-1" title="modifica" size={20} onClick={(e: React.MouseEvent<SVGAElement>) => {
                e.preventDefault();
                handleClickItemEdit(row);
              }} />
          </>
          )}
        </span>),
      ignoreRowClick: true,
      button: true,
      grow: 1,
    },
  ] as TableColumn<ICertificationEntity>[];

  const handleChange = (selected: any) => {
    setSelectedCertificationRows(selected.selectedRows);
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', selected.selectedRows);
  };

  const handleChangeStatoCertificazione = (option: IMetadataOption) => {
    setLoading(true);
    dispatch(putCertificationsStatus({ status: option.id, ids: selectedCertificationRows.map(row => row.id) }))
      .unwrap()
      .then(() => setPagination({ ...pagination, page: 1 }))
      .catch((err) => {
        setLoading(false);
      })
  };

  const handleDelete = () => {
    if (selectedId) {
      setLoading(true);
      dispatch(deleteCertification({ id: selectedId } as IDeleteCertificationThunkPayload))
        .unwrap()
        .then(() => {
          handleCloseDelete();
          setSelectedItem(null);
          setPagination({ ...pagination, page: 1 });
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Fragment>
      <Container fluid as="section">
        <Row>
          <Col className="mb-3">
            <InputGroup className="w-100">
              <FormControl placeholder="Cerca digitando: codice fiscale, cognome e/o nome, email e premi INVIO..." id="idTextSearch"
                onChange={handleChangeText}
                onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    handleFilterByText();
                  }
                }} />
              <InputGroup.Text title="Pulisci filtro ricerca" className="c-pointer"
                onClick={(event: React.MouseEvent) => {
                  event.preventDefault();
                  const element = document.getElementById("idTextSearch") as HTMLInputElement;
                  if (element) {
                    element.value = '';
                  }
                  handleClearFilterByText();
                }}><X size={20} /></InputGroup.Text>
              <InputGroup.Text title="Inizia la ricerca" className="c-pointer"
                onClick={(event: React.MouseEvent) => {
                  event.preventDefault();
                  handleFilterByText();
                }}><Search size={20} /></InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <InputGroup className="d-flex justify-content-end">
              {/* <OggettoTipologia onSelectOggetto={handleFilterByOggetto} onSelectTipologia={handleFilterByTipologia} /> */}
              <Triennio onSelect={handleFilterByTriennio} />
              {isMeseAnnoView ? (
              <MeseAnno onSelectMonth={handleFilterByMese} onSelectYear={handleFilterByAnno} />
              ) : (
                <div></div>
              )}
              <Oggetto selectAll={true} triennio={filter.triennio} anno={filter.annoInizio} onSelectOggetto={handleFilterByOggetto} />
              {/* <Oggetto selectAll={true} onSelectOggetto={handleFilterByOggetto} /> */}
              {(filter.oggetto == 1 || filter.oggetto == 5) ? (<Tipologia selectAll={true} anno={filter.annoInizio} triennio={filter.triennio} onSelectTipologia={handleFilterByTipologia} />) : ""}
              {(filter.oggetto == 2 || filter.oggetto == 3 || filter.oggetto == 4) ? (<Esonero selectAll={true} anno={filter.annoInizio} triennio={filter.triennio} onSelectEsonero={handleFilterByEsonero} />) : ""}
              <StatoCertificazione onSelect={handleFilterByStato} />              
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="mx-1">
          {(certifications.length > 0 ? "certificazioni " + (1 + pagination.limit * (pagination.page-1)) + "-" + (certifications.length + pagination.limit * (pagination.page-1)) + 
                                (certifications.length >= countTotalCertifications ? "" : " di " + countTotalCertifications) : "0 certificazioni TOTALI")}
          </Col>
          <Col>
            <InputGroup className="d-flex justify-content-end">
              <PageSelect defaultSelectedId={1} totPagine={Math.ceil(countTotalCertifications / pagination.limit)} onSelect={handlePaginationLoad} />
              <Sorting initSortingOptions={CertificationOrderBy} onSelectOrderBy={handleSortingOrderBy} onSelectOrderType={handleSortingOrderType} />
              <PaginationLimit defaultSelectedId={pagination.limit} onSelect={handlePaginationLimit} enableUnlimit={false} />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            {
              isLoading ? (
                <Fragment />
              ) : (
                selectedCertificationRows.length ? (
                  <Alert variant="dark">
                    <section className="d-flex">
                      <div className="flex-grow-1 d-flex">
                        <h6><b>{selectedCertificationRows.length}</b> record selezionati</h6>
                      </div>
                      <div className="flex-shrink-1 d-flex">
                        <Button variant="secondary" className="mx-1" onClick={handleClearChangeStatoCertificazione}>
                          Annulla
                        </Button>
                        <Dropdown className="mx-1">
                          <Dropdown.Toggle variant="dark" id="dropdown-change-statocertificazione">
                            Assegna stato
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="px-1 border border-secondary">
                            {
                              stati.length ? (
                                stati.map((item: IMetadataOption, index: number) => {
                                  return (
                                    <Dropdown.Item key={"dropdownChangeStatoCertificazione" + item.id} onClick={() => handleChangeStatoCertificazione(item)}>
                                      {item.nome}
                                    </Dropdown.Item>
                                  );
                                })
                              ) : (
                                <Fragment />
                              )
                            }
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </section>
                  </Alert>
                ) : (
                  <Fragment />
                )
              )
            }
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <DataTable columns={columns} data={certifications} selectableRows onSelectedRowsChange={handleChange}
              responsive striped dense progressPending={isLoading} noDataComponent={`${error ? error.error : 'Nessun risultato'}`}
              clearSelectedRows={toggleSelectedCertificationRowsCleared} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Pagination className="d-flex justify-content-center">
              <Pagination.First onClick={() => handlePaginationLoad(1)} disabled={(pagination.page - 1) === 0}>Prima pagina</Pagination.First>
              <Pagination.Prev key={"idPaginationItemPrev"} onClick={handlePaginationLoadMinus} disabled={isLoading || (pagination.page - 1) === 0}>Precedente</Pagination.Prev>
              <Pagination.Item key={pagination.page} disabled={isLoading} onClick={() => handlePaginationLoad(pagination.page)}>{pagination.page}</Pagination.Item>
              <Pagination.Next key={"idPaginationItemNext"} onClick={handlePaginationLoadMore} disabled={isLoading || pagination.limit != certifications.length}>Successiva</Pagination.Next>
              <Pagination.Last onClick={() => handlePaginationLoad(Math.ceil(countTotalCertifications / pagination.limit))} disabled={pagination.limit != certifications.length}>Ultima pagina</Pagination.Last>
            </Pagination>
          </Col>
        </Row>
      </Container>

      <Modal size="lg" centered show={showDetailModal} onHide={handleCloseDetailModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="display-6 m-0 p-0">Certificazione / Esonero</p> 
            {selectedItem ? (<p className="blockquote m-0 p-0">richiesta {/*<b>#</b><u>{selectedItem.id}</u>*/} inserita il {format(new Date(selectedItem.dataCreazione), 'dd/MM/yyyy')}</p>) : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedItem ? (
            <CertificationDetail certification={selectedItem} />
          ) : (
            <Fragment />
          )}
        </Modal.Body>
      </Modal>

      <Modal size="xl" centered show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="display-6 m-0 p-0">Certificazione / Esonero</p>
            {selectedItem ? (<p className="blockquote m-0 p-0">richiesta {/*<b>#</b><u>{selectedItem.id}</u>*/} inserita il {format(new Date(selectedItem.dataCreazione), 'dd/MM/yyyy')}</p>) : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedItem ? (
            <CertificationEdit certification={selectedItem} submitted={false} />
          ) : (
            <Fragment />
          )}
        </Modal.Body>
      </Modal>

      <Modal centered show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title><p className="display-6 m-0 p-0">Certificazione / Esonero</p> {selectedId ? (<p className="blockquote m-0 p-0">CANCELLA richiesta {/*<b>#</b><u>{selectedId}</u>*/}</p>) : ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body><p>Confermi la cancellazione?<br /><small>Cliccando sul bottone ELIMINA verrà cancellata la richiesta selezionata.</small></p></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete} disabled={isLoading}>Annulla</Button>
          <Button variant="danger" onClick={handleDelete} disabled={isLoading}>Elimina</Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default CertificationList;