import { FC, Fragment, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { IMetadataOption, IMetadataOptionNode } from '../../../../entities/metadata.entity';
import { RootState, AppDispatch } from '../../../../redux/store';
import { BsFillCircleFill } from "react-icons/bs";
import { getLineeGuida } from '../../../../redux/slices/lineeguida.slice';
import { ILineaGuidaEntity } from '../../../../entities/lineeguida.entity';

export const getTipologia = (id: number, list: Array<IMetadataOption>): string => {
  if (list.length) {
    const option = list.find((elem) => elem.id === id);
    if (option) {
      return option.nome;
    }
  }
  return '';
}

export const getTipologiaOption = (id: number, list: Array<IMetadataOption>): IMetadataOption | null => {
  if (list.length) {
    const option = list.find((elem) => elem.id === id);
    if (option) {
      return option;
    }
  }
  return null;
}

interface IProps {
  defaultSelectedId?: number;
  anno?: number;
  triennio? : number;
  onSelect?: (option: IMetadataOption | null) => void;
}

const Tipologia: FC<IProps> = (props) => {

  const tipologieCorsi: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.course.tipologia_corso);
  // const [tipologieDistinte, setTipologieDistinte] = useState<Array<IMetadataOption>>([]);
  const [tipologieCorsiLG, setTipologieCorsiLG] = useState<Array<IMetadataOptionNode>>([]);
  
  const trienni: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.trienni);
  const lineeGuida: Array<ILineaGuidaEntity> = useSelector((s: RootState) => s.lineeGuida.lineeGuida);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getLineeGuida({}));
  }, [dispatch]); 

  // useEffect(() => {
  //   const uniqueNames = new Set<string>();
  //   const uniqueOptions: IMetadataOption[] = [];    
  //   tipologieCorsi.forEach((item) => {
  //     if (!uniqueNames.has(item.nome)) {
  //       uniqueNames.add(item.nome);
  //       uniqueOptions.push({
  //         id: item.id,
  //         nome: item.nome,
  //         descrizione: item.descrizione,
  //       });
  //     }
  //   });
  //   setTipologieDistinte(uniqueOptions);
  // }, [tipologieCorsi]);

  useEffect(() => {
    
    // console.log("triennio: ", props.triennio);
    // console.log("anno: ", props.anno);
    // console.log("trienni: ", trienni?.length ? trienni : "vuoto");
    // console.log("lineeGuida: ", lineeGuida?.length ? lineeGuida : "vuoto");
    setTipologieCorsiLG([]);
    if (trienni.length && lineeGuida.length) {
      const anniInEsame: number[] = [];
      if (props?.anno && props.anno > 0) {
        anniInEsame.push(props.anno);
      } else if (props.triennio && props.triennio > 0) {
        const triennio = trienni.find((elem) => elem.id === props.triennio);
        anniInEsame.push(...(triennio?.descrizione ? triennio.descrizione : "").split('-').map(Number));
      } else {
        setTipologieCorsiLG(tipologieCorsi)
      }

      if (anniInEsame.length > 0) {
      // estrarre linee guida relative all'anno ( props.anno compreso tra inizio e fine validità della linea guida) -> tipologie relative        
        const lineeGuidaProcessed = new Set<number>();

        anniInEsame.forEach((annoInEsame) => {
          const lineeGuidaFilter = lineeGuida.filter((elem) => {
            const compreso = annoInEsame >= new Date(elem.inizioValidita).getFullYear() && 
              annoInEsame <= new Date(elem.fineValidita).getFullYear();
            const duplicato = lineeGuidaProcessed.has(elem.id);
            if (compreso && !duplicato) {
              lineeGuidaProcessed.add(elem.id);
              return true;
            }
            return false;
          });
          lineeGuidaFilter.forEach((elem) => {
            setTipologieCorsiLG((prev) => [ ...prev, ...tipologieCorsi.filter((tip) => tip.lg === elem.id)]);
          });
        });
      }

    }
  }, [trienni, lineeGuida, props.anno, props.triennio]);

  const defaultSelectedOption = (): IMetadataOption | null => {
    if (props?.defaultSelectedId) {
      return getTipologiaOption(props.defaultSelectedId, tipologieCorsi);
    }
    return null;
  };

  const [filterByTipologia, setFilterByTipologia] = useState<IMetadataOption | null>(defaultSelectedOption());

  const handleFilterByTipologia = (option: IMetadataOption | null) => {
    setFilterByTipologia(option);
    if (props?.onSelect) {
      props.onSelect(option);
    }
  };

  return (
    <Fragment>
      <Dropdown className="mx-2 my-2">
        <Dropdown.Toggle variant="outline-primary">
          {filterByTipologia != null ? (
            <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill p-1">
              <BsFillCircleFill className="text-success" size={20} title="Filtro attivo" />
            </span>
          ) : ""}
          {`Tipologia ${filterByTipologia ? filterByTipologia.id.toString().substring(0, 10) : ''} `}
        </Dropdown.Toggle>
        <Dropdown.Menu className="px-1 border border-primary">
          <Dropdown.Item key={"dropdownTipologiaNone"} disabled={filterByTipologia == null} onClick={() => handleFilterByTipologia(null)}>Tutte</Dropdown.Item>
          {
            tipologieCorsiLG.length ? (
              tipologieCorsiLG.map((item: IMetadataOptionNode, index: number) => {
                return (
                  <Dropdown.Item key={"dropdownTipologia" + item.id} disabled={filterByTipologia != null && item.id === filterByTipologia.id} onClick={() => handleFilterByTipologia(item)}>
                    {"linea guida " + item.lg + " - " + item.nome}
                  </Dropdown.Item>
                );
              })
            ) : (
              <Fragment />
            )
          }
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};

export default Tipologia;